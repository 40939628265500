export default {
  parent: "Other",
  routers: [
    {
      path: "system",
      name: "System",
      component: () => import("@/views/system/SystemView.vue"),
    },
    {
      path: "setting",
      name: "Setting",
      component: () => import("@/views/setting/SettingView.vue"),
    },
    {
      path: "bpo",
      name: "BPO",
      component: () => import("@/views/bpo/BpoView"),
    },
  ],
};
