import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store"; // 引入 Vuex store

// 禁止全局路由错误处理打印，是vue-router开发者给出的 NavigationDuplicated 解决方案
// 也可以为 每个router.push增加回调函数  成本高
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [];

// 引入路由
let map = {};
let req = require.context("./modules", true, /\.js$/);
req.keys().forEach((path) => {
  let routerObj = req(path)?.default;
  if (routerObj) {
    routerObj.routers.forEach((item) => {
      if (routerObj.parent) item["parent"] = routerObj.parent;
      map[item["name"]] = item;
    });
  }
});

// 将平铺结构整理为树状结构(多级嵌套)
Object.values(map).forEach((item) => {
  if (item.parent) {
    let p = map[item.parent];
    (p.children || (p.children = [])).push(item);
  } else {
    routes.push(item);
  }
});

routes.push({
  path: "*",
  name: "404",
  component: () =>
    import(/* webpackChunkName: "404" */ "@/views/error/NotFound.vue"),
});

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// 这里是前置路由守卫
router.beforeEach((to, from, next) => {
  // 在此处使用 Vuex store
  if (to.path.includes("/recruit")) {
    store.dispatch("user/updateModule", 12); // 调用 Vuex action 更新 module 值
  } else if (to.path.includes("/rpo")) {
    store.dispatch("user/updateModule", 13);
  }
  next();
});
// 这里是后置路由守卫
// router.afterEach((to, from) => {
// });

export default router;
