var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tui-conversation-list"},[(_setup.isShowOverlay)?_c(_setup.ActionsMenu,{attrs:{"selectedConversation":_setup.currentConversation,"actionsMenuPosition":_setup.actionsMenuPosition,"selectedConversationDomRect":_setup.currentConversationDomRect},on:{"closeConversationActionMenu":_setup.closeConversationActionMenu}}):_vm._e(),_vm._l((_setup.allConversationList.sort(_setup.compareFun)),function(conversation,index){return _c('div',{key:index,class:[
      'TUI-conversation-content',
      !_setup.isPC && 'TUI-conversation-content-h5',
    ],attrs:{"id":`convlistitem-${index}`}},[(conversation.isSystemMessage)?[_c('div',{staticClass:"item-wrapper",class:_setup.props.currentSystemMessageType === conversation.type ? 'item-selected' : '',on:{"click":function($event){return _setup.handleItemClick(conversation.type)}}},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"avatar",style:({ backgroundColor: conversation.bgColor })},[_c('i',{staticClass:"iconfont",class:conversation.icon})])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-header"},[_c('label',{staticClass:"content-header-label"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(conversation.name))])]),_c('div',{staticClass:"middle-box"},[_c('p',{staticClass:"middle-box-content"},[_vm._v(_vm._s(conversation.newsContent))])])]),_c('div',{staticClass:"content-footer"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(_setup.calculateTimestamp(conversation.lastMessage.lastTime * 1000)))]),(conversation.unreadCount > 0)?_c('span',{staticClass:"num"},[_c('span',{staticClass:"num-inner"},[_vm._v(_vm._s(conversation.unreadCount > 99 ? "99+" : conversation.unreadCount))])]):_vm._e()])])])])]:[_c('div',{class:[
        _setup.isPC && 'isPC',
        'TUI-conversation-item',
        _setup.currentConversationID === conversation.conversationID && !_setup.props.currentSystemMessageType &&
          'TUI-conversation-item-selected',
        conversation.isPinned && 'TUI-conversation-item-pinned',
      ],on:{"click":function($event){return _setup.enterConversationChat(conversation.conversationID)},"longpress":function($event){return _setup.showConversationActionMenu($event, conversation, index)},"contextmenu":function($event){$event.preventDefault();return _setup.showConversationActionMenu($event, conversation, index, true)}}},[_c('aside',{staticClass:"left"},[_c(_setup.Avatar,{attrs:{"useSkeletonAnimation":"","url":conversation.getAvatar(),"nick":conversation.getShowName(),"size":"36px","borderRadius":"50%"}}),(_setup.userOnlineStatusMap && _setup.isShowUserOnlineStatus(conversation))?_c('div',{class:[
            'online-status',
            Object.keys(_setup.userOnlineStatusMap).length > 0 &&
            Object.keys(_setup.userOnlineStatusMap).includes(
              conversation.userProfile.userID
            ) &&
            _setup.userOnlineStatusMap[conversation.userProfile.userID]
              .statusType === 1
              ? 'online-status-online'
              : 'online-status-offline',
          ]}):_vm._e()],1),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-header"},[_c('label',{staticClass:"content-header-label"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(conversation.getShowName()))])]),_c('div',{staticClass:"middle-box"},[(
                conversation.type === 'GROUP' &&
                conversation.groupAtInfoList &&
                conversation.groupAtInfoList.length > 0
              )?_c('span',{staticClass:"middle-box-at"},[_vm._v(_vm._s(conversation.getGroupAtInfo()))]):_vm._e(),_c('p',{staticClass:"middle-box-content"},[_vm._v(" "+_vm._s(conversation.getLastMessage("text"))+" ")])])]),_c('div',{staticClass:"content-footer"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(conversation.getLastMessage("time")))]),(conversation.unreadCount > 0 && !conversation.isMuted)?_c('span',{staticClass:"num"},[_c('span',{staticClass:"num-inner"},[_vm._v(_vm._s(conversation.unreadCount > 99 ? "99+" : conversation.unreadCount))])]):_vm._e(),(conversation.unreadCount > 0 && conversation.isMuted)?_c('span',{staticClass:"num-notify"}):_vm._e()])])])]],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }