//全局公共方法，调用方式为this.$commons+方法名，如this.$commons.stopScorll()
import QRCode from "qrcodejs2";
import { Message } from "element-ui";
import { resumecss } from "@/assets/style/resume.css.js";

let commons = {};

//生成二维码
commons.creatQrFn = function (url, id, size) {
  new QRCode(id, {
    width: size, // 设置宽度，单位像素
    height: size, // 设置高度，单位像素
    text: "" + url, // 设置二维码内容或跳转地址
  });
};

//下载生成的二维码
commons.downLoadQrFn = function (id) {
  let myCanvas = document.getElementById(id).getElementsByTagName("canvas");
  let a = document.createElement("a");
  a.href = myCanvas[0].toDataURL("image/png");
  a.download = "二维码";
  a.click();
};

//页面滚动
commons.stopScorll = function () {
  document.body.style.overflow = "hidden"; //禁止页面划动
};
commons.moveScorll = function () {
  document.body.style.overflow = ""; //出现滚动条
};
//弹窗提示
commons.voit = function (val, type) {
  Message.closeAll();
  Message({
    message: val || "功能开发中，敬请期待",
    type: type ? type : "warning",
  });
};

//宽度自适应
commons.autoWidth = function (value) {
  if (value == "" || value == 0) {
    return "85px";
  } else {
    return String(value).length * 12 + 20 + "px";
  }
};
//时间宽度自适应
commons.autoTimeWidth = function (value) {
  if (value == "" || value == 0) {
    return "95px";
  } else {
    return String(value).length * 8.5 + "px";
  }
};

// 密码安全级别检测
commons.analyzePasswordSecurityLevel = function (password) {
  let securityLevelFlag = 0;
  if (password.length < 6) {
    return 0;
  } else {
    var securityLevelFlagArray = new Array(0, 0, 0, 0);
    for (var i = 0; i < password.length; i++) {
      var asciiNumber = password.substr(i, 1).charCodeAt();
      if (asciiNumber >= 48 && asciiNumber <= 57) {
        securityLevelFlagArray[0] = 1; //digital
      } else if (asciiNumber >= 97 && asciiNumber <= 122) {
        securityLevelFlagArray[1] = 1; //lowercase
      } else if (asciiNumber >= 65 && asciiNumber <= 90) {
        securityLevelFlagArray[2] = 1; //uppercase
      } else {
        securityLevelFlagArray[3] = 1; //specialcase
      }
    }

    for (let i = 0; i < securityLevelFlagArray.length; i++) {
      if (securityLevelFlagArray[i] == 1) {
        securityLevelFlag++;
      }
    }
    return securityLevelFlag - 1;
  }
};

//时间戳转换，时间戳转时间
commons.MillisecondToDate = function (value, format, connect) {
  if (!value) {
    return false;
  }
  let date;
  if (typeof value == "string" && value.constructor == String) {
    date = new Date(value.replace(/-/g, "/"));
  } else {
    date = new Date(value);
  }
  let y = date.getFullYear(),
    m = date.getMonth() + 1,
    d = date.getDate(),
    h = date.getHours(),
    i = date.getMinutes(),
    s = date.getSeconds();
  if (m < 10) {
    m = "0" + m;
  }
  if (d < 10) {
    d = "0" + d;
  }
  if (h < 10) {
    h = "0" + h;
  }
  if (i < 10) {
    i = "0" + i;
  }
  if (s < 10) {
    s = "0" + s;
  }
  let t = value ? y + "-" + m + "-" + d + " " + h + ":" + i : "--";
  if (format) {
    t = value ? y + "-" + m + "-" + d : "--";
  }
  if (connect && connect.length) {
    t = value ? y + connect[0] + m + connect[1] + d + connect[2] : "--";
  }
  return t;
};

//计算年龄/日期
commons.getAgeFn = function (str, type) {
  if (!str) {
    return 0;
  }
  let value = "";
  if (type == 1) {
    //计算年龄
    //出生时间 毫秒
    let birthDayTime = new Date(str).getTime();
    //当前时间 毫秒
    let nowTime = new Date().getTime();
    //一年毫秒数(365 * 86400000 = 31536000000)
    value = Math.ceil((nowTime - birthDayTime) / 31536000000);
  } else {
    //获取日期
    let myDate = new Date(str);
    let year = myDate.getFullYear(); //获取当前年
    let mon =
      myDate.getMonth() + 1 < 10
        ? "0" + (myDate.getMonth() + 1)
        : myDate.getMonth() + 1; //获取当前月
    value = year + "-" + mon;
  }

  return value;
};
//get导出
commons.download = function (url, name, type, method) {
  console.log(url, name, type, "params=====");
  var xhr = new XMLHttpRequest();
  xhr.open(method ? method : "GET", url, true);
  //xhr.setRequestHeader("Content-Type","application/json");
  xhr.responseType = "blob"; // 返回类型blob
  // 定义请求完成的处理函数，请求前也可以增加加载框/禁用下载按钮逻辑
  xhr.onload = function (res) {
    // 请求完成
    console.log(this.status, "this.status");
    if (this.status === 200) {
      // 返回200
      var blob = this.response;
      var href = window.URL.createObjectURL(blob); //创建下载的链接
      console.log(res, blob, href, "导出");
      // if(blob.type=="application/json" || blob.size==0){
      if (blob.size == 0) {
        Message({
          message: "导出失败",
          type: "error",
        });
        return;
      }
      if (blob.type == "application/json") {
        blob = new File([JSON.stringify(blob)], ".json", {
          type: "application/json",
          lastModified: Date.now(),
        });
      }
      // }
      console.log(type, "type=");
      var date = new Date(); //实例一个时间对象；
      var y = date.getFullYear(); //获取系统的年；
      var M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1; //获取系统月份，由于月份是从0开始计算，所以要加1
      var d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(); //获取系统日
      var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(); //获取系统时间
      var mm =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(); //分
      var ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); //秒
      name = name + "_" + y + M + d + h + mm + ss;

      //判断是否是IE浏览器，是的话返回true
      if (window.navigator.msSaveBlob) {
        try {
          window.navigator.msSaveBlob(blob, name + type);
        } catch (e) {
          console.log(e, "eee");
        }
      } else {
        // 谷歌浏览器 创建a标签 添加download属性下载
        var downloadElement = document.createElement("a");
        downloadElement.href = href;
        downloadElement.target = "_blank";
        downloadElement.download = name + type; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
      }
    } else {
      if (this.status == 404) {
        Message({ message: "导出失败,文件未找到", type: "error" });
      } else {
        Message({ message: "导出失败", type: "error" });
      }
      return;
    }
  };
  // 发送ajax请求
  xhr.send();
};
//post导出文件,params是post请求需要的参数，url是请求url地址
commons.postExcelFile = function (params, url) {
  var form = document.createElement("form");
  form.style.display = "none";
  form.action = url;
  form.method = "post";
  document.body.appendChild(form);

  for (var key in params) {
    var input = document.createElement("input");
    input.type = "hidden";
    input.name = key;
    input.value = params[key];
    form.appendChild(input);
  }

  form.submit();
  form.remove();
};
// post导出文件
commons.postdownload = function (params, url, token) {
  var xhr = new XMLHttpRequest();
  xhr.open("POST", url, true);
  xhr.setRequestHeader("Content-Type", "application/json");

  xhr.setRequestHeader("access_token", token); // 将 token 拼接到 access_token 请求头中
  // 将参数对象转换为 JSON 字符串
  var jsonData = JSON.stringify(params);
  xhr.responseType = "blob"; // 返回类型blob

  xhr.onload = function (res) {
    if (this.status === 200) {
      // 请求成功的处理逻辑
      // console.log(xhr.responseText);
      var blob = this.response;
      var href = window.URL.createObjectURL(blob); //创建下载的链接
      if (blob.size == 0) {
        Message({
          message: "导出失败",
          type: "error",
        });
        return;
      }
      window.open(href);
      console.log(res, blob, href, "导出");
    } else {
      if (this.status == 404) {
        Message({ message: "导出失败,文件未找到", type: "error" });
      } else {
        Message({ message: "导出失败", type: "error" });
      }
      return;
    }
  };

  xhr.send(jsonData);
};

//通知：附件字符串处理，返回对应数据
commons.temInfo = function (item, type) {
  let info = "";
  let arr = [];
  let l = 0;
  if (type == "name") {
    //返回附件名称
    arr = item.split("/");
    l = arr.length;
    info = arr[l - 1];
  } else if (type == "format") {
    //返回格式
    arr = item.split(".");
    l = arr.length;
    info = arr[l - 1];
  } else if (type == "err") {
    //返回格式对应的error图片
    arr = item.split(".");
    l = arr.length;
    switch (arr[l - 1].toLowerCase()) {
      case "docx":
      case "doc":
        info = require("@/assets/public/office/word.png");
        break;
      case "xlsx":
      case "xls":
        info = require("@/assets/public/office/excel.png");
        break;
      case "jpg":
      case "jpeg":
      case "gif":
      case "png":
        info = require("@/assets/public/office/jpg.png");
        break;
      case "pdf":
        info = require("@/assets/public/office/pdf.png");
        break;
      case "pptx":
      case "ppt":
        info = require("@/assets/public/office/ppt.png");
        break;
      case "txt":
        info = require("@/assets/public/office/txt.png");
        break;
      case "rar":
      case "zip":
      case "7z":
      case "arj":
        info = require("@/assets/public/office/rar.png");
        break;
      case "avi":
      case "mov":
      case "qt":
      case "asf":
      case "rm":
      case "navi":
      case "mp4":
        info = require("@/assets/public/office/video.png");
        break;
      case "mp3":
      case "mpeg":
      case "wma":
      case "ra":
      case "rmx":
        info = require("@/assets/public/office/music.png");
        break;
      case "html":
      case "htm":
      case "java":
      case "jsp":
      case "php":
      case "css":
      case "js":
      case "asp":
      case "vue":
      case "xml":
        info = require("@/assets/public/office/code.png");
        break;
      default:
        info = require("@/assets/public/office/no.png");
        break;
    }
  } else if (type == "img") {
    //返回正常图片
    arr = item.split(".");
    l = arr.length;
    if (
      arr[l - 1].toLowerCase() == "jpg" ||
      arr[l - 1].toLowerCase() == "jpeg" ||
      arr[l - 1].toLowerCase() == "gif" ||
      arr[l - 1].toLowerCase() == "png"
    ) {
      info = item;
    } else {
      info = ""; //后续开发文档转图片
    }
  }
  return info;
};
// const fileToPng = "?ci-process=doc-preview&dstType=png"; // 腾讯云缩略图后缀参数
const fileToHtml = "?ci-process=doc-preview&dstType=html&htmltitle=";
// 附件迁移服务器后删除
commons.getFileViewUrl = function (url, title) {
  let str = this.getFormat(url, 1);
  title = title ? this.safeTextToBase64(title) : this.safeTextToBase64("预览");
  let fileViewUrl = url + (str == "img" ? "" : fileToHtml + title);
  return fileViewUrl;
};
// URL 安全的 BASE64 编码,目前该方法只应用于文件预览是，传值给腾讯云文件url
commons.safeTextToBase64 = function (str) {
  let Base64 = require("js-base64").Base64;
  str = Base64.encode(str); //Base64.encode(item.attachName); //代码文本， 需Base64处理
  str = str
    .replaceAll("+", "-")
    .replaceAll("/", "_")
    .replaceAll("=", "");
  return str;
};
commons.getFormat = function (str, backType) {
  let format = "";
  if (str) {
    format = str.substring(str.lastIndexOf(".") + 1);
  }
  if (backType) {
    let data = [
      { format: "excel", type: ["xls", "xlsx"] },
      { format: "word", type: ["doc", "docx"] },
      { format: "img", type: ["jpg", "jpeg", "png", "gif"] },
      { format: "music", type: ["mp3", "mp4"] },
      { format: "pdf", type: ["pdf"] },
      { format: "txt", type: ["txt"] },
      { format: "ppt", type: ["ppt"] },
    ];
    let img = "no";
    if (format) {
      data.forEach((el) => {
        if (el.type.findIndex((e) => e == format) > -1) {
          img = el.format;
        }
      });
    }
    if (backType == 1) {
      return img; //返回格式名称
    } else if (backType == 2) {
      return require("@/assets/image/office/ico_" + img + ".png"); //返回格式小图标
    } else if (backType == 3) {
      return require("@/assets/image/office/" + img + ".png"); //返回格式大图片
    }
  } else {
    return format; //返回格式名如docx、xml、png等，不带.
  }
};
//获取Cascader级联回显数据,objStr数据字符串，type是否数字类型,mark=1级联单选
commons.getCascaderDataFn = function (objStr, type, mark) {
  let arr = [];
  if (objStr) {
    if (mark == 1) {
      //单选
      objStr = String(objStr);
      for (let i = 0; i < String(objStr).length / 2; i++) {
        arr.push(
          type
            ? Number(objStr.slice(0, (i + 1) * 2))
            : objStr.slice(0, (i + 1) * 2)
        );
      }
    } else {
      //多选
      String(objStr)
        .split(",")
        .forEach((el) => {
          let newArr = [
            type ? Number(el.slice(0, 2)) : el.slice(0, 2),
            type ? Number(el) : el,
          ];
          if (el.length > 4) {
            //存在三级
            newArr.push(type ? Number(el.slice(0, 4)) : el.slice(0, 4));
          }
          arr.push(newArr);
        });
    }
  }
  return arr;
};

//数组去重,arr为数组，name为不可重复参数名
commons.unique = function (arr, name) {
  // 如果新数组的当前元素的索引值 == 该元素在原始数组中的第一个索引，则返回当前元素
  return arr.filter(function (x, index) {
    let arrids = [];
    arr.forEach((item) => {
      arrids.push(item[name]);
    });
    return arrids.indexOf(x[name]) === index;
  });
};

//设置附件选择格式范围
commons.fileType = function (type) {
  if (type == 1) {
    //图片
    //return 'image/jpeg,image/png,image/gif';
    return ".jpg,.png,.gif";
  } else if (type == 2) {
    //文档
    //return 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/ppt, application/pdf';
    return ".doc,docx,.xls,.xlsx,.pdf,.ppt,.pptx";
  } else if (type == 3) {
    //压缩包
    return ".zip,.rar,.7z";
  } else if (type == 4) {
    //视频
    //return 'video/*';
    return ".ogm,.wmv,.avi,.m4v,.mpg,.mpeg,.mp4";
  } else if (type == 5) {
    //音频
    //return 'audio/*';
    return ".mp3,.wma,.m4a,.ogg,.wav";
  } else {
    return "*";
  }
};

//文本域内容在div中显示时处理换行
commons.textToDiv = function (content) {
  return content ? content.replace(/\n/g, "<br>") : "";
};

//区分字符串和JSON字符串
commons.isJsonObj = function (str) {
  try {
    if (typeof JSON.parse(str) == "object") {
      return true;
    }
  } catch (e) {
    return false;
  }
};

//将base64转换为file类型
commons.dataURLtoFile = function (dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

//生成简历html
commons.getHtmlContent = function (template) {
  let html = `<!DOCTYPE html>
  <html>
  <head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width,initial-scale=1.0">
  <title>matcheasy</title>
  <link type="text/css" rel="stylesheet" href="//at.alicdn.com/t/font_2166248_hc6o7jy80i5.css">
  <link rel="stylesheet" href="https://unpkg.com/element-ui/lib/theme-chalk/index.css">
  <link rel="stylesheet" href="http://file.asktrue.co/bucket1/front/public/public.css">
  <style>
  ${resumecss}
  </style>
  </head>
  <body>
  <div class="resume-view-page" style="width:1000px;margin:30px auto;border:1px solid#e7e7e7;">
    <div class="jl-warp">
      ${template}
    </div>
  </div>
  </body>
  </html>`;
  return html;
};

//三方登录,type登录类型，platformInfo平台信息，page回调页面
commons.otherLogin = function (type, platformInfo, page) {
  let url = "";
  let appId;
  let hostname = location.hostname; //'matcheasy.co'//location.hostname
  if (type == 1) {
    appId = platformInfo.wxAppidKf || "wxd2663e099f9a001b";
    url =
      "https://open.weixin.qq.com/connect/qrconnect?appid=" +
      appId +
      "&redirect_uri=" +
      encodeURIComponent("http://" + hostname + "/" + page + "loginType=wx") +
      "&response_type=code&scope=snsapi_login";
  } else if (type == 2) {
    appId = platformInfo.qqAppid || "102009195";
    console.log("http://" + hostname + "/" + page + "loginType=qq", "------");
    url =
      "https://graph.qq.com/oauth2.0/authorize?client_id=" +
      appId +
      "&redirect_uri=" +
      encodeURIComponent("http://" + hostname + "/" + page + "loginType=qq") +
      "&response_type=code";
    // return false;
  } else if (type == 3) {
    appId = platformInfo.sinaAppid || "2994031587";
    // https://api.weibo.com/oauth2/authorize?client_id=YOUR_CLIENT_ID&response_type=code&redirect_uri=YOUR_REGISTERED_REDIRECT_URI
    url =
      "https://api.weibo.com/oauth2/authorize?client_id=" +
      appId +
      "&response_type=code" +
      "&redirect_uri=" +
      encodeURIComponent("http://" + hostname + "/#/" + page);
  } else {
    appId = platformInfo.alipayAppid || "2021003126633786";
    url =
      "https://auth.alipay.com/login/index.htm?goto=https%3A%2F%2Fopenauth.alipay.com%3A443%2Foauth2%2FpublicAppAuthorize.htm%3Fapp_id%3D" +
      appId +
      "%26scope%3Dauth_user%26redirect_uri%3D" +
      encodeURIComponent(
        "http://" + hostname + "/" + page + "loginType=alipay"
      ) +
      "%26response_type%3Dcode%26scope%3Dsnsapi_login";
  }
  console.log(hostname, appId, "====hostname,appId");
  return url;
};

commons.UrlSearch = function (strV) {
  var obj = {};
  var name, value;
  var str = strV ? strV : location.href; //取得整个地址栏
  var num = str.indexOf("?");
  str = str.substr(num + 1); //取得所有参数 stringvar.substr(start [, length ]

  var arr = str.split("&"); //各个参数放到数组里
  for (var i = 0; i < arr.length; i++) {
    num = arr[i].indexOf("=");
    if (num > 0) {
      name = arr[i].substr(0, num);
      value = arr[i].substr(num + 1);
      obj[name] = decodeURIComponent(value);
    }
  }
  return obj;
};

// 可能有云掌门加入的员工  云掌门头像与好招的逻辑不一样 这里处理成好招的逻辑
commons.adjustStaffPhotoWebAddr = function (staffPhotoWebAddr = "") {
  if (staffPhotoWebAddr && staffPhotoWebAddr.indexOf("http") > -1) {
    return staffPhotoWebAddr;
  }
  return "";
};

// 获取视频形式名称
commons.getInterviewFormLabel = function (form = 0) {
  return ["--", "现场面试", "视频面试", "人机交互"][form];
};

commons.getInterviewRoomAddress = function ({
  compId,
  planId,
  memberId,
  interviewForm,
}) {
  // 忘了为什么 ${planId}${memberId}
  return `${window.location.href.split("/#/")[0]
    }/#/ms/room/${compId}/${planId}/${planId}${memberId}?form=${interviewForm}`;
};

export default commons;
