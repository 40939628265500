<!--
自定义iconfont图标+文字样式
props:{
    name: String，图标样式名称,
    size: String，图标大小（不含文本）,如：15px
    reverse: Boolean,是否颠倒顺序，默认false
    direction: String, 显示方向：row(水平方向)/column（垂直方向）
    gap: String, 图文间距
    text: String, 显示文本
}
-->
<template>
  <div class="g-icon" :style="{ flexDirection, gap }">
    <div
      class="iconfont"
      :class="[name]"
      :style="{ fontSize: size, color }"
      @click="$emit('click', $event)"
    ></div>
    <div
      class="label"
      :style="{ color: textColor, fontSize: textSize }"
      v-if="text"
    >
      <slot>{{ text }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "g-icon",
  props: {
    name: String, //图标名称
    size: String, //图标大小（不含文本）
    color: String, //图标颜色（不含文本），默认继承
    reverse: { type: Boolean, default: false }, //颠倒顺序
    direction: { type: String, default: "row" }, //方向：row(水平方向)/column（垂直方向）
    gap: String, //间距
    text: [String, Number], //显示文本
    textColor: String, //文本颜色，默认继承
    textSize: String, //文本大小，默认继承
  },
  computed: {
    flexDirection() {
      let str = this.direction + (this.reverse ? "-reverse" : "");
      return str;
    },
  },
};
</script>

<style lang="less" scoped>
.g-icon {
  display: flex;
  align-items: center;
  line-height: 1;
  .iconfont {
    color: inherit;
    line-height: inherit;
  }
  .label {
    font-size: inherit;
    line-height: 1;
  }
}
</style>
