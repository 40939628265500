export default function (axios) {
  return {
    // 获取公司信息
    // export const getCompanyInfo = (data) => getReq(apiUrl+'/compMemberCtl/selectCompMemberById',data);
    getCompanyInfo(params) {
      return axios.get("/compMemberCtl/selectCompMemberById", { params });
    },
    getMatcheasyCompanyInfo(params) {
      return axios.get("/compMemberCtl/selectCompMemberById", { params });
    },

    // // 公司信息修改
    // //export const updateMemberComp = (data) => postReq(apiUrl+'/companyMemberCtl/updateMemberComp',data,{ContentType:'application/json;charset=UTF-8'});
    // export const updateMemberComp = (data) => postReq(apiUrl+'/compMemberCtl/updateMemberComp',data,{ContentType:'application/json;charset=UTF-8'});

    // // 获取公司安全信息
    // export const getComSafetyInfo = () => getReq(apiUrl+'/compMemberCtl/selectUserInfoById',{});
    getComSafetyInfo(params) {
      return axios.get("/compMemberCtl/selectUserInfoById", { params });
    },
    // // 判断账号绑定的手机号是否唯一
    // export const checkMobilePhone = (data) => getReq(apiUrl+'/cUser/checkMobilePhone',data);

    // // 判断账号绑定的邮箱是否唯一
    // export const checkEmail = (data) => getReq(apiUrl+'/cUser/checkEmail',data);

    // // 邮箱解绑验证
    // export const checkVerifyEmail = (data) => getReq(apiUrl+'/compMemberCtl/checkVerifyEmail',data);

    // // 手机解绑验证
    // export const checkVerifyPhone = (data) => getReq(apiUrl+'/compMemberCtl/checkVerifyPhone',data);

    // // 邮箱绑定
    // export const bindEmail = (data) => postReq(apiUrl+'/compMemberCtl/bindEmail',data);

    // // 手机号绑定
    // export const bindMobile = (data) => postReq(apiUrl+'/compMemberCtl/confirmMobileCode',data);

    // // 子账号邮箱绑定
    // export const bindChildEmail = (data) => postReq(apiUrl+'/compStffMember/bindEmail',data);

    // // 子账号手机号绑定
    // export const bindChildMobile = (data) => postReq(apiUrl+'/compStffMember/confirmMobileCode',data);

    // //员工权限
    // export const getPower = (data) => getReq(apiUrl+'/cPowerSetCtl/selectPower',data);//查询
    getPower(params) {
      return axios.get("/cPowerSetCtl/selectPower", { params });
    },
    // export const getLeftPower = (data) => getReq(apiUrl+'/cAccountAuthCtl/selectAuthByUserId',data);//查询
    getLeftPower(params) {
      return axios.get("/cAccountAuthCtl/selectAuthByUserId", { params });
    },
    // export const updatePower = (data) => postReq(apiUrl+'/cPowerSetCtl/updatePower',data,{ContentType:'application/json;charset=UTF-8'});//变更
    updatePower(params) {
      return axios.post("/cPowerSetCtl/updatePower", params);
    },
    // export const updateAuthPower = (data) => postReq(apiUrl+'/cAccountAuthCtl/updatePAccountAuth',data,{ContentType:'application/json;charset=UTF-8'});//变更
    // // 更新左侧树状结构
    // export const updatePAccountAuth = data => postReq(apiUrl+"/cAccountAuthCtl/updatePAccountAuth",data,{ContentType:'application/json;charset=UTF-8'})
    // // 查询权限树状结构
    // export const selectMenuTree = data => getReq(apiUrl+"/cAccountAuthCtl/selectMenuTree",data)
    selectMenuTree(params) {
      return axios.get("/cAccountAuthCtl/selectMenuTree", { params });
    },
    // //保存新增发票信息
    // export const insertReceiptInfo = data => postReq(apiUrl+"/cReceiptInfoCtl/insertReceiptInfo",data,{ContentType:'application/json;charset=UTF-8'})
    // //修改发票信息保存
    // // export const insertReceiptInfo = data => postReq(apiUrl+"/cReceiptInfoCtl/updateReceiptInfo",data,{ContentType:'application/json;charset=UTF-8'})
    // //发票信息
    // export const selectReceiptInfo = data => getReq(apiUrl+"/cReceiptInfoCtl/selectReceiptInfo",data)
    // export const selectPersonOrderInfo = data => getReq(apiUrl+"/cPayAccount/selectAccount",data)
    // //邮寄地址查询
    // export const selectMailInfo= data => getReq(apiUrl+"/cReceiptMailInfoCtl/selectMailInfo",data)
    // //保存发票新增邮寄地址
    // export const insertMailInfo = data => postReq(apiUrl+"/cReceiptMailInfoCtl/insertMailInfo",data,{ContentType:'application/json;charset=UTF-8'})
    // //删除发票信息、设为默认
    // export const  updateReceiptStatus= data => postReq(apiUrl+"/cReceiptInfoCtl/updateReceiptStatus",data)
    // //修改发票信息
    // export const updateReceiptInfo = data => postReq(apiUrl+"/cReceiptInfoCtl/updateReceiptInfo",data,{ContentType:'application/json;charset=UTF-8'})
    // //删除邮寄地址
    // export const updateEmailStatus = data => postReq(apiUrl+"/cReceiptMailInfoCtl/updateEmailStatus",data)
    // //修改邮寄地址保存
    // export const updateEmailInfo   = data => postReq(apiUrl+"/cReceiptMailInfoCtl/updateEmailInfo",data,{ContentType:'application/json;charset=UTF-8'})

    //员工权限
    // export const getPower = (data) => getReq(apiUrl + '/cPowerSetCtl/selectPower', data);//查询
    // export const getLeftPower = (data) => getReq(apiUrl + '/cAccountAuthCtl/selectAuthByUserId', data);//查询
    // export const updatePower = (data) => postReq(apiUrl + '/cPowerSetCtl/updatePower', data, { ContentType: 'application/json;charset=UTF-8' });//变更
    // export const updateAuthPower = (data) => postReq(apiUrl + '/cAccountAuthCtl/updatePAccountAuth', data, { ContentType: 'application/json;charset=UTF-8' });//变更
    // 更新左侧树状结构
    // export const updatePAccountAuth = data => postReq(apiUrl + "/cAccountAuthCtl/updatePAccountAuth", data, { ContentType: 'application/json;charset=UTF-8' })
    // 查询权限树状结构
    // export const selectMenuTree = data => getReq(apiUrl + "/cAccountAuthCtl/selectMenuTree", data)
  };
}
