export default function (axios) {
  return {

    // 验证码
    asktrueCoderReq(userLoginCode, type) {
      return axios.get("/anon/verifyCode", {
        params: { system: 99, userLoginCode, verifyType: type ? type : 2 },
      });
    },

    // 海报
    asktruePosterReq(params) {
      return axios.post("/anon/trial/apply", params, {
        headers: { "Content-Type": "application/json;charset=UTF-8" },
      });
    },
    // 校验公司名称是否已存在
    asktrueCompNameRepeat(params) {
      return axios.get("/anon/trial/checkCompName", { params });
    },
  };
}
