// 王超临时控制台路由
export default {
  routers: [
    {
      path: "/manage/console",
      name: "Console",
      component: () => import("@/views/console/consoleView.vue"),
    },
  ],
};
