export default {
  namespaced: true,
  local: true,
  state: {
    access_token: "",
    userId: "", // 用户id
    compId: "", // 企业id
    compName: "", // 公司简称
    userInfo: {},
    userName: "",
    userFace: "", //用户（公司）头像
    userSign: "", // 用户身份标识 company企业 staff员工
    systemSign: "", // 1 hrm（甲方） 、2 hro（乙方） systemSign 为空表示没有外包业务
    staffType: "", // 1 合同 、2 外包
    module: "", // 招聘12  RPO13
    compAdmin: 0, //0不是管理员，1普通管理员，2超级管理员（注意此处描述的管理员并非权限中设置的管理员，与isAdmin和isAdminObj无任何关系）
    user: {
      sysType: "",
      userId: "", //登录者id
      userLoginCode: "", //
      userName: "", //登录者姓名
      userType: "", //登录者类型：2-公司；其他是员工
      vipId: "", //登录者公司id
    },
    userInfo: {},
  },
  mutations: {
    SET_LOGINUSER: (state, data) => {
      state.userInfo = data;
      state.userInfo.module = state.module;
      sessionStorage.setItem("Matcheasy_userInfo", JSON.stringify(data));
      // 判断当前登录的网站 本地登录时 注释掉下面代码是cn控制台 打开时是co控制台（发布时记得打开注释）暂时不存到vuex里
      // state.userInfo.webSite =
      //   window.location.host.indexOf(".cn") != -1 ? "cn" : "co";
    },
    login(state, info) {
      if (info.access_token) state.access_token = info.access_token;
      if (info.userId) state.userId = info.userId;
      if (info.vipId) state.compId = info.vipId;
      if (info.userName) state.userName = info.userName;
      if (info.userType)
        state.userSign = info.userType == 2 ? "company" : "staff";
      // if (info.module) state.systemSign = info.module;
      if (info.sysType) state.systemSign = info.sysType;
      if (info.staffType) state.staffType = info.staffType;
      if (info.module) state.module = info.module;
      if (info.compAdmin || info.compAdmin == 0) {
        state.compAdmin = info.compAdmin;
      }
      if (info.user) state.user = info.user;
      if (info.userInfo) state.userInfo = info.userInfo;
    },
    logout(state) {
      state.access_token = "";
      state.compId = "";
      state.userId = "";
      state.userName = "";
      state.userFace = "";
      state.userType = "";
      state.systemSign = "";
      state.staffType = "";
      state.module = "";
      state.compAdmin = 0;
      state.user = {};
      state.userInfo = {};
    },
    updateModule(state, newModule) {
      state.module = newModule;
    },
  },
  actions: {
    logout({ commit, dispatch }) {
      commit("logout");

      // 清除 好招 和 asktrue 相关的token
      dispatch(
        "recruit/setRecruitToken",
        { code: false, token: "" },
        { root: true }
      );
      dispatch(
        "asktrue/setAsktrueToken",
        { code: false, token: "" },
        { root: true }
      );
      dispatch(
        "matcheasy_asktrue/setAsktrueToken",
        { code: false, token: "" },
        { root: true }
      );
    },
    updateModule({ commit }, newModule) {
      commit("updateModule", newModule);
    },
  },
};
