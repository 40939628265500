const apiUrl10 = "https://project.asktrue.co"; //阿仕图-考试
const apiUrl1 = "https://user.asktrue.co"; //阿仕图-用户
// const axios = require("axios").default;
const Axios = require("axios");
const axios = Axios.create({
  baseURL: apiUrl10,
});
const axios1 = Axios.create({
  baseURL: apiUrl1,
});
// 添加请求拦截器
// oaAxios.interceptors.request.use(
//   function (config) {
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );
// 添加响应拦截器
axios.interceptors.response.use(
  function (res) {
    let data = res.data;
    // if (data.code != 200 && data.code != 0) {
    //   Vue.prototype.$message.error(data.msg || "接口报错" + data.code);
    //   throw data.msg || "接口报错" + data.code;
    // }
    return data;
  },
  function (error) {
    return Promise.reject(error);
  }
);
axios1.interceptors.response.use(
  function (res) {
    let data = res.data;
    // if (data.code != 200 && data.code != 0) {
    //   Vue.prototype.$message.error(data.msg || "接口报错" + data.code);
    //   throw data.msg || "接口报错" + data.code;
    // }
    return data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default function () {
  return {
    //笔试安排获取阿仕图同步考试数据
    getSyncExamReq(params) {
      return axios.get("/projectBase/getExamNamNose", { params });
    },
    //笔试安排-长链接转短网址(好招用)
    getShorlUrlReq(params) {
      return axios.post("/dwz/getShorlUrlNose", params, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },

  };
}
