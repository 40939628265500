<!--
 * @Date: 2024-04-17 15:48:55
 * @LastEditors: cyy
 * @LastEditTime: 2024-10-18 10:27:04
 * @Description: 
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    //加载系统参数
    this.$store.dispatch("exam/getDictNoseFn", this.$api);
  },
};
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100%;
}
:root {
  --base-theme-color: @theme-general;
  --second-theme-color: @theme-general;
  --second-theme-color-rgb: @theme-general-rgb;
}
/* 通用颜色变量（默认主题 theme-0） */
:root {
  --navbar-bg: #fff; /* 导航栏右侧背景页面背景 */
  --page-bg: #fff; /* 页面背景 */
  --text-secondary: #999; /* 次要文字颜色 （顶部左侧导航栏文字颜色）*/
  --text-muted: #666; /* 辅助文字颜色（顶部左侧导航栏图标文字颜色） */
  --nav-person-text: #39c; /* 导航栏顶部切换身份文字颜色 */
  --navbar-border-color: #e4e4e4; /* 顶部导航边框颜色 */
  --nav-color: #c0c4cc; //顶部导航栏左侧图标色
  --quick-nav-color: #999; /* 顶部导航栏 快捷导航文字颜色 */
  --nav-size-color-t: #999; /* 顶部导航导航小旗文字颜色 */
  --menu-bg-color: #fff; //左侧导航栏背景色
  --menu-hover-bg-color: #f7f9fa; /* 悬停时背景色 */
  --menu-choose-bg-color: #ebedf1; /* 左侧导航栏选中颜色 */
  --menu-border-color: #e4e4e4; //左侧导航栏边框颜色
  --text-secondary-color: #999; //左侧导航栏标题字体颜色
  --text-muted-color: #999; //左侧导航栏文字颜色
  --dui-color: #000; //选择背景颜色里对号的样式颜色
}

.theme-0 {
  --navbar-bg: #fff; /* 导航栏右侧背景页面背景 */
  --page-bg: #fff; /* 页面背景 */
  --text-secondary: #999; /* 次要文字颜色 （左侧导航栏文字颜色）*/
  --text-muted: #666; /* 辅助文字颜色（左侧导航栏图标文字颜色） */
  --nav-person-text: #39c; /* 导航栏顶部切换身份文字颜色 */
  --navbar-border-color: #e4e4e4; /* 顶部导航边框颜色 */
  --nav-color: #c0c4cc; //顶部导航栏左侧图标色

  --quick-nav-color: #999; /* 顶部导航栏 快捷导航文字颜色 */
  --nav-size-color-t: #999; /* 顶部导航导航小旗文字颜色 */
  --menu-bg-color: #fff; //左侧导航栏背景色
  --menu-hover-bg-color: #f7f9fa; /* 悬停时背景色 */
  --menu-choose-bg-color: #f7f9fa; /* 左侧导航栏选中颜色 */
  --menu-border-color: #e4e4e4; //左侧导航栏边框颜色
  --text-secondary-color: #999; //左侧导航栏标题字体颜色
  --text-muted-color: #999; //左侧导航栏文字颜色
  --dui-color: #000; //选择背景颜色里对号的样式颜色
}
/* 样式1（蓝色主题） */
.theme-1 {
  --navbar-bg: #0b628e;
  --page-bg: #f5f5f5;
  --text-secondary: #999;
  --text-muted: #666;
  --nav-person-text: #39c; /* 导航栏顶部切换身份文字颜色 */
  --navbar-border-color: #0b628e;
  --nav-color: #fff; //顶部导航栏左侧图标色
  --quick-nav-color: #999;
  --nav-size-color-t: #999;
  --menu-bg-color: #1f76a2;
  --menu-hover-bg-color: #2a7da7; /* 悬停时背景色 */
  --menu-choose-bg-color: #0b628e; /* 左侧导航栏选中颜色 */
  --menu-border-color: #5899ba;
  --text-secondary-color: #fff;
  --text-muted-color: #fff;
  --dui-color: #fff;
}

/* 样式2（黑色主题） */
.theme-2 {
  --navbar-bg: #202020; /* 导航栏右侧背景页面背景 */
  --page-bg: #202020; /* 页面背景 */
  --text-secondary: #fff; /* 次要文字颜色 （左侧导航栏文字颜色）*/
  --text-muted: #fff; /* 辅助文字颜色（左侧导航栏图标文字颜色） */
  --nav-person-text: #fff; /* 导航栏顶部切换身份文字颜色 */
  --navbar-border-color: #383838; /* 顶部导航边框颜色 */
  --nav-color: #fff; //顶部导航栏左侧图标色
  --quick-nav-color: #9a9a9a; /* 顶部导航栏 快捷导航文字颜色 */
  --nav-size-color-t: #999; /* 顶部导航导航小旗文字颜色 */
  --menu-bg-color: #202020; //左侧导航栏背景色
  --menu-hover-bg-color: #2c2c2c; /* 悬停时背景色 */
  --menu-choose-bg-color: #1b5bc9; /* 左侧导航栏选中颜色 */
  --menu-border-color: #2c2c2c; //左侧导航栏边框颜色
  --text-secondary-color: #fff; //左侧导航栏标题字体颜色
  --text-muted-color: #fff; //左侧导航栏文字颜色
  --dui-color: #fff; //选择背景颜色里对号的样式颜色
}
/* 样式3（好招主题） */
.theme-3 {
  --navbar-bg: #2f3745;
  --page-bg: #f5f5f5;
  --text-secondary: #999;
  --text-muted: #666;
  --nav-person-text: #39c; /* 导航栏顶部切换身份文字颜色 */
  --navbar-border-color: #4b5463;
  --nav-color: #fff; //顶部导航栏左侧图标色
  --quick-nav-color: #999;
  --nav-size-color-t: #999;
  --menu-bg-color: #374151;
  --menu-hover-bg-color: #4b5463; /* 悬停时背景色 */
  --menu-choose-bg-color: #4b5463; /* 左侧导航栏选中颜色 */
  --menu-border-color: #4b5463;
  --text-secondary-color: #fff;
  --text-muted-color: #fff;
  --dui-color: #fff;
}
/* 样式4（echarts主题） */
.theme-4 {
  --navbar-bg: #172233;
  --page-bg: #f5f5f5;
  --text-secondary: #999;
  --text-muted: #666;
  --nav-person-text: #39c; /* 导航栏顶部切换身份文字颜色 */
  --navbar-border-color: #3c485e;
  --nav-color: #fff; //顶部导航栏左侧图标色
  --quick-nav-color: #999;
  --nav-size-color-t: #999;
  --menu-bg-color: #202d42;
  --menu-hover-bg-color: #3c485e; /* 悬停时背景色 */
  --menu-choose-bg-color: #3c485e; /* 左侧导航栏选中颜色 */
  --menu-border-color: #3c485e;
  --text-secondary-color: #fff;
  --text-muted-color: #fff;
  --dui-color: #fff;
}
/* 样式5（蓝黄主题） */
.theme-5 {
  --navbar-bg: #042357;
  --page-bg: #042357;
  --text-secondary: #999;
  --text-muted: #666;
  --nav-person-text: #9cb0cf; /* 导航栏顶部切换身份文字颜色 */
  --navbar-border-color: #274171;
  --nav-color: #fff; //顶部导航栏左侧图标色
  --quick-nav-color: #9cb0cf;
  --nav-size-color-t: #9cb0cf;
  --menu-bg-color: #0c3477;
  --menu-hover-bg-color: #254786; /* 悬停时背景色 */
  --menu-choose-bg-color: #254786; /* 左侧导航栏选中颜色 */
  --menu-border-color: #274171;
  --text-secondary-color: #cedcf1;
  --text-muted-color: #cedcf1;
  --dui-color: #fff;
}
</style>
