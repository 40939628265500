export default {
  parent: "train",
  routers: [
    // 课程
    {
      path: "course",
      name: "Course",
      component: () => import("@/views/train/course/tabView.vue"),
      // redirect: { name: "MyDiary" },
      meta: { title: "课程" },
    },
    // 直播
    {
      path: "live",
      name: "Live",
      component: () => import("@/views/train/live/tabView.vue"),
      // redirect: { name: "MyDiary" },
      meta: { title: "直播" },
    },
    // 培训班
    {
      path: "class",
      name: "Class",
      component: () => import("@/views/train/class/tabView.vue"),
      // redirect: { name: "MyDiary" },
      meta: { title: "培训班" },
    },
    // **************************************************************************学员
    // 学员
    {
      path: "student",
      name: "Student",
      component: () => import("@/views/train/student/tabView.vue"),
      meta: { title: "学员" },
    },
    // **************************************************************************讲师
    // 讲师
    {
      path: "lecturer",
      name: "Lecturer",
      component: () => import("@/views/train/lecturer/tabView.vue"),
      // redirect: { name: "MyDiary" },
      meta: { title: "讲师" },
    },
    // **************************************************************************测验
    // 测验
    {
      path: "studyexam",
      name: "Studyexam",
      component: () => import("@/views/train/exam/indexView.vue"),
      // redirect: { name: "MyDiary" },
      meta: { title: "测验" },
    },
    // 商城
    {
      path: "studyMall",
      name: "StudyMall",
      component: () => import("@/views/train/mall/tabView.vue"),
      // redirect: { name: "MyDiary" },
      meta: { title: "商城" },
    },
  ],
};
