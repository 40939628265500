export default function (axios) {
  return {
    //发送邮件
    postSendMailReq(data) {
      return axios.post("/mail/sendMail", data);
    },
    //     // 支付宝支付
    aliPay(data) {
      return axios.post("/anon/aliPayCtl/qrcode", data);
    },
    // // 获取支付宝和微信支付状态
    getAliAndWxStatus(params) {
      return axios.get("/anon/payCtl/selectTransactionFlowingStatus", {
        params,
      });
    },
    // // 微信支付
    wxPay(data) {
      return axios.post("/anon/weChatPayCtl/qrcode", data);
    },
    // // 余额支付
    balancePay(data) {
      return axios.post("/anon/payCtl/balancePay", data);
    },
    // 获取平台信息
    getPlatInfo(params) {
      return axios.get("/admin/plat/selectNose", {
        params,
      });
    },
    getShorlUrlReqCom(data) {
      return axios.post("/dwz/getShorlUrl", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    //企业会员图片上传
    comImgUplodeReq(data) {
      return axios.post("/file/upload?fileDirType=comp_img", data);
    },

    //子账号会员图片上传
    //export const chilImgUplodeReq = data => uplodeReq(apiUrl+"/file/upload?fileDirType=child_img",data)
    // export const chilImgUplodeReq = data => uplodeReq(apiUrl+"/file/upload?fileDirType=child_img",data)
    //个人会员图片上传
    // export const perImgUplodeReq = data => uplodeReq(apiUrl+"/file/upload?fileDirType=per_img",data)
    //简历附件上传
    resumeFileUplodeReq(data, type) {
      let url =
        "/file/upload" +
        (type == 1 ? "Cover" : "") +
        "?fileDirType=resume_file";
      return axios.post(url, data);
    },

    //获取系统参数通用方法(单级)
    getMatcheasyDictNoseReq(params) {
      return axios.get("/parameter/getDictNose", { params });
    },

    //获取签名
    getMatcheasyUserSigReq(params) {
      return axios.get("/userSig/getUserSig", {
        params,
      });
    },
  };
}
