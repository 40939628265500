/*
 * @Description: asktrue 接口 的 token
 * @Date: 2023-11-17 10:05:34
 * @LastEditTime: 2024-01-19 12:07:48
 */
import { getUserInfoReq } from "@/api/asktrue/user/user";

export default {
  namespaced: true,
  local: true,
  state: {
    access_token: "",
    asktrue_token: "", // // 企业登录的时候 token / none  员工登录的时候 token / noneComp 这种情况是企业没在好招注册 noneStaff  /  这种情况是员工没在好招注册
    asktrue_vipId: "",
    userInfo: {
      userId: null,
    },
    userId: null, // 用户id 与 access_token 对应
    projectInfo: {},
    paperInfo: {}, //问卷信息
    paperQuesInfo: [], //问卷试题信息
  },
  mutations: {
    setAsktrueToken(state, data) {
      state.asktrue_token = data.token;
      state.asktrue_vipId = data.vipId || "";
      if (data.code) {
        state.access_token = data.token;
      } else {
        state.access_token = "";
      }
    },
    setProInfo(state, data) {
      state.projectInfo = Object.assign({}, data);
    },
    setUserInfo(state, data) {
      state.userInfo = Object.assign({}, data);
      state.userId = data.userId;
    },
    setPaperInfo(state, data) {
      state.paperInfo = Object.assign({}, data);
    },
    updatePaperQuesInfo(state, data) {
      state.paperQuesInfo = data;
    },
  },
  actions: {
    // 更新登录用户信息
    getUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.access_token) {
          getUserInfoReq()
            .then((res) => {
              if (res.code !== "200") {
                return reject("Verification failed, please Login again.");
              }
              commit("setUserInfo", res.data.result);
              resolve(res.data.result);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },

    setAsktrueToken({ commit, dispatch }, data) {
      commit("setAsktrueToken", data);
      if (data.code) {
        dispatch("setAccess_token1", data.token, { root: true });
        dispatch("asktrue/getUserInfo", {}, { root: true });
      }
    },
  },
};
