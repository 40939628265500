export default {
  parent: "Staff",
  routers: [
    {
      path: "staffmanage",
      name: "StaffManage",
      component: () => import("@/views/staff/AssistantView.vue"),
    },
    // 人事助理
    // {
    //   path: "assistant",
    //   name: "Assistant",
    //   component: () => import("@/views/staff/AssistantView.vue"),
    // },
    {
      path: "staffarchives",
      name: "StaffArchives",
      component: () => import("@/views/staff/ArchivesShunt.vue"),
    },
    // 员工关系--劳动合同↓
    {
      path: "laborcontract/:sign?",
      name: "LaborContract",
      component: () => import("@/views/staff/contract/ContractShunt.vue"),
    },
    {
      path: "staffsurvey",
      name: "StaffSurvey",
      component: () => import("@/views/staff/StaffSurveyShunt.vue"),
    },
    // 问卷调查
    {
      path: "questionnaire",
      name: "Questionnaire",
      component: () => import("@/views/staff/questionnaire/IndexMenuView.vue"),
    },
    // 活动报名
    {
      path: "activity",
      name: "Activity",
      component: () => import("@/views/staff/activity/IndexMenuView.vue"),
    },
    // {
    //   path: "360",
    //   name: "360",
    //   component: () => import("@/views/staff/360/IndexView.vue"),
    // },
    // 考试测评
    {
      path: "atexam",
      name: "AtExam",
      component: () => import("@/views/staff/exam/IndexMenuView.vue"),
    },
    {
      path: "addressBook",
      name: "AddressBook",
      component: () => import("@/views/address/addressBook"),
    },
  ],
};
