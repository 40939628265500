/*
 * @Description: asktrue 接口 的 token
 * @Date: 2023-11-17 10:05:34
 * @LastEditTime: 2024-01-09 11:00:01
 */
import { getUserInfoReq } from "@/api/asktrue/user/user";

export default {
  namespaced: true,
  state: {
    access_token: sessionStorage.getItem("access_token") || "",
    asktrue_token: sessionStorage.getItem("asktrue_token") || "",
    asktrue_vipId: sessionStorage.getItem("asktrue_vipId") || "",
    system: 99, // 商城用到了 平台编码，固定使用 asktrue.co的平台编码
    userInfo: {
      userId: null,
    },
    userId: null, // 用户id 与 access_token 对应
    projectInfo: {},
    paperInfo: {}, //问卷信息
    paperQuesInfo: [], //问卷试题信息
    auToolbox: sessionStorage.getItem("auToolbox") || "", //工具权限 -1没有 如果工具不为-1 代表工具下的栏目都有权限
    auEvaluation: sessionStorage.getItem("auEvaluation") || "", //测评权限 -1没有  如果工具为-1 测评不为-1 则代表测评有权限
  },
  mutations: {
    setAsktrueToken(state, data) {
      sessionStorage.setItem("asktrue_token", data.code ? data.token : "");
      sessionStorage.setItem("asktrue_vipId", data.vipId || "");
      state.asktrue_token = data.token;
      state.asktrue_vipId = data.vipId || "";
      if (data.code) {
        sessionStorage.setItem("access_token", data.token);
        state.access_token = data.token;
      } else {
        sessionStorage.setItem("access_token", "");
        state.access_token = "";
      }
    },
    setProInfo(state, data) {
      state.projectInfo = Object.assign({}, data);
    },
    setUserInfo(state, data) {
      state.userInfo = Object.assign({}, data);
      state.userId = data.userId;
    },
    setPaperInfo(state, data) {
      state.paperInfo = Object.assign({}, data);
    },
    updatePaperQuesInfo(state, data) {
      state.paperQuesInfo = data;
    },
    // 判断是否有测评查看权限
    updateAuToolbox(state, data) {
      sessionStorage.setItem("auToolbox", data);
      state.auToolbox = data;
    },
    updateAuEvaluation(state, data) {
      sessionStorage.setItem("auEvaluation", data);
      state.auEvaluation = data;
    },
  },
  actions: {
    // 更新登录用户信息
    getUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.access_token) {
          getUserInfoReq()
            .then((res) => {
              if (res.code !== "200") {
                return reject("Verification failed, please Login again.");
              }
              commit("setUserInfo", res.data.result);
              resolve(res.data.result);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
  },
};
